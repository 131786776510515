/* Vendor imports */
import React from 'react'
import PropTypes from 'prop-types'
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'
/* App imports */
import Button from '../../components/button'
import Config from '../../../config'
import Utils from '../../utils'
import style from './archive-pagination.module.less'
import { theme } from "../../theme.js"

const ArchivePagination = ({ prevPage, nextPage }) => (
  <div className={style.container}>
    {prevPage ? (
      <Button
        href={"/archive/" + prevPage}
        buttonStyle={style.buttonLeft}
        theme={theme}
        text="&lt; Newer posts"
      />
    ) : null}
    {nextPage ? (
      <Button
        href={"/archive/" + nextPage}
        buttonStyle={style.buttonRight}
        theme={theme}
        text="Older posts &gt;"
      />

    ) : null}
  </div>
)

ArchivePagination.propTypes = {
  prevPage: PropTypes.number,
  nextPage: PropTypes.number,
}

export default ArchivePagination
